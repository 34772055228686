<template>
  <div id="links">
    <router-link :to="{ name: 'viewer', params: { linkID: linkTitle }}">{{ label(linkTitle) }}</router-link>
  </div>
</template>

<script>

export default {
  name: "Links",
  props: ['linkTitle'],
  data() {
    return {
      nomFichier: "",
    };
  },
  methods: {
    label(nom) {
      let linkSplit  = this.linkTitle.split('/');
      let linkSplit2 = linkSplit[1].split('.pdf');
      return linkSplit2[0].substring(9);
    }
  }
}
</script>

<style>
#links a {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
}
</style>