<template>
  <div class="titre404">Spiacenti, non siamo riusciti a trovare la pagina che stai cercando</div>
  <div class="text404">Clicca su uno dei link sottostanti</div>
  <div id="img404">
    <keep-alive>
      <img src="@/assets/img/not-found.png" width="150" />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>

.titre404 {
  text-align: center;
  font-size: 35px;
  width: 590px;
  font-family: Oswald;
  font-weight: bold;
  margin: 0 auto;
  padding-top: 30px;
  line-height: 1.4;
}
.text404 {
  text-align: center;
  font-size: 25px;
  font-family: Oswald;
  margin: 0 auto;
  padding-top: 20px;
}
#img404 {
  margin-top: 30px;
}
</style>