<template>
  <div id="admin">
    <div>
      <div class="title-admin">AMMINISTRAZIONE APP</div>
      <div class="wrapper-adm grid p-fluid">
        <div class="wrapper-group">
          <Toast position="bottom-center" group="resultGroup" />
          <Button @click="lauchAddDocs" label="Aggiungi documenti dalla posta mail" />
          <ConfirmDialog />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import 'primeflex/primeflex.css';


export default {
  name: "app",
  components: { Button, ConfirmDialog, Toast },
  data() {
    return {
      passValue: null,
      activeIndex: 1,
      errorMessage: "",
      localPass: null,
      level100: false,
    };
  },
  created() {
      this.$store.commit( 'SET_ERROR_MESSAGE', "" )
      this.localPass = localStorage.getItem('passAnnuario');
      if ( !this.localPass || this.localPass != 100 ) {
        this.$store.commit( 'SET_LOGIN_ACTIF', true )
        console.log('not localpass');
      }
  },
  methods: {
    verifyLogin() {
      console.log('pass through... verifyLogin', this.passValue);
      this.$store.commit( 'SET_LOGIN_PASS', this.passValue );
      this.$store.dispatch('loginVerify');
    },
    hideDialog() {
      console.log('hideDialog loginPass', this.$store.state.loginPass);
      if ( !localStorage.getItem('passAnnuario')  ) {
        this.$store.commit( 'SET_LOGIN_ACTIF', false )
        this.$router.push('/');
      }
      else {
        this.localPass = localStorage.getItem('passAnnuario');
      }
    },
    lauchAddDocs() {
      this.$confirm.require({
        message: 'Sicuro di voler procedere?',
        header: 'Conferma',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        defaultFocus: 'reject',
        accept: () => {
          axios.get( this.$store.state.host + 'php/readMail.php')
            .then(response => {
              console.log( ' readMail = ',  response.data)
              var result = response.data;
              if ( result == "---" ) {
                var suc = "error";
                var sum = "Nessun documento aggiunto !";
                var det = ""; }
              else {
                suc = "success";
                sum = "I documenti sono stati aggiunti!";
                det = "";
                result.forEach(element => {
                  det += element.name + ": " + element.nbr + " ";
                });
              }
              this.$toast.add({
                severity: suc, 
                summary:  sum, 
                detail:   det,
                position: 'bottom-center',
                group: 'resultGroup'
              });
            })
        },
//          reject: () => {}
      });
    }
  }
}
</script>

<style>
.p-dialog-content {
  padding-bottom: 5px !important;
}
.pass-diag-class .p-dialog-footer {
  text-align: left !important; 
  color: red !important;
}
.progress-spinner {
    position: fixed;
    z-index: 999999;
    height: 6em;
    width: 6em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.title-admin { 
  font-size: 20px;
  font-weight: bold;
  font-family: 'Oswald';
  padding: 10px 0 30px;
}
.wrapper-group {
    text-align: left;
    margin: 0 20px;
}
</style>