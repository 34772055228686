<template>
  <Button @click="openPosition('top')" label="Inviare e-mail" />
  <Dialog header="Inserire indirizzo E-mail" 
    v-model:visible="displayPosition" 
    :style="{ width: '90%', 'max-width': '500px', 'min-width': '295px' }" 
    position="top" 
    :modal="true">
    <span class="p-float-label">
        <InputText id="emailadr" @keydown="clearError" @keyup.enter="sendEmail" type="text" v-model="emailAddress" />
        <label for="emailadr">E-mail</label>
    </span>
    <span class="error">{{ emailError }}</span>
    <template #footer>
        <Button label="Invia" @click="sendEmail" autofocus />
    </template>
  </Dialog>
  <Toast position="top-center" group="resultGroup" :style="{ 'width': '100%', 'max-width': '400px' }" />
  <vue-pdf-embed :source="url" />
</template>

<script>
import axios from 'axios'
import VuePdfEmbed from 'vue-pdf-embed'
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';

export default {
  components: { VuePdfEmbed, Button, Dialog, InputText, Toast },
  name: "Viewer",
  data() {
    return {
      url: null,
      emailError: "",
      emailAddress: null,
      displayPosition: false,
    };
  },
  mounted() {
    this.url = this.$store.state.host + "doc/" + this.$route.params.linkID;
    this.emailAddress = localStorage.getItem('email');
  }, 
  methods: {
    sendEmail() {
      if ( this.emailAddress && this.validateEmail( this.emailAddress ) ) {
        console.log ('host = ', this.$store.state.host );
        axios.get( this.$store.state.host + 'php/sendMail.php', { params: { fichier: this.url, mailTo: this.emailAddress } })
          .then(response => {
            console.log( ' readMail = ',  response.data)
            localStorage.setItem( 'email', this.emailAddress );
            this.displayPosition = false;

            // affiche un toast
            this.$toast.add({
              severity: "success", 
              summary:  "Il documento è stato spedito", 
              detail:   this.emailAddress,
              position: 'top-center',
              group: 'resultGroup',
              life: 3000
            });
            
          })
      }
      else {
        this.emailError = "Indirizzo email invalido"
      }
    },
    openPosition(position) {
        this.displayPosition = true;
    },
    validateEmail (email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) 
    },
    clearError() {
      this.emailError = "";
    }
  }
}
</script>

<style scoped>
button { margin-top: 10px; }
.error { color: red !important; }
</style>
