import { createApp } from 'vue'
import App from './App.vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
import PrimeVue from 'primevue/config'
import TabMenu from 'primevue/tabmenu';
import Router from './router'
import Store from './store'

import './assets/css/styles.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
//import 'primeflex/primeflex.css'
//import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

const app = createApp(App)
app.use(VueAxios, Axios)
app.use(PrimeVue)
app.use(Router)
app.use(Store)
//app.use(ConfirmationService);
app.use(ToastService);

app.component('TabMenu', TabMenu);

app.mount('#app')

