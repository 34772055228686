<template>
  <div id="collegio" v-if="annuarioDBOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/">Annuario</router-link>
    </div>
    <div class="titre-principal centre" style="padding-bottom: 20px">
      Collegio dei Penitenzieri Vaticani
    </div>
    <div class="centre fs18 bold">
      <router-link :to="{ name: 'viewer', params: { linkID: 'orario_basilica/999999 - Orario Settimanale Basilica.pdf' }}">Visualizza l'orario settimanale in Basilica</router-link>
    </div>
    <div
      class="list-center"
      v-for="(membro, index) in this.annuarioDB[1]"
      :key="membro.immagine"
    >
      <router-link
        :to="{
          name: 'SchedaFrate',
          params: { noFrate: index, id: membro.immagine },
        }"
        @click="memberClicked(index)"
      >
        <Card style="margin: 10px">
          <template #content style="text-align: left">
            {{ membro.titolo }}
            <strong>{{ membro.cognome.toUpperCase() }}</strong>
            {{ membro.nome }}
          </template>
        </Card>
      </router-link>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "Collegio",
  components: { Card },
  data() {
    return {
      annuarioDisplay: {},
    };
  },
  computed: {
    ...mapState(["annuarioDB", "annuarioDBOpen", "annuarioDBPending"]),
  },
  created() {
    if (!this.annuarioDBOpen) {
      console.log("Annuario.vue mounted");
      this.$store.dispatch("getAnnuario");
    }
    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  mounted() {
    this.$store.dispatch("setHighlight", { on: true }); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch("setHighlight", { on: false }); // highlight the tab of tabMenu
  },
  methods: {
    memberClicked(memberIndex) {
      this.$schedaFrate = memberIndex;
      window.schedaFrate = memberIndex;

      console.log("index = ", this.$schedaFrate);
      console.log("window.index = ", window.schedaFrate);
    },
  },
};
</script>

<style>
#collegio .p-card,
#collegio .p-card-body {
  border-radius: 10px !important;
}
#collegio .p-card-body {
  background-color: #6c8cbf; /* #c9c9ff; */
}
#collegio .p-card-content {
  color: #fffa8f;
  font-size: 22px;
}
#collegio .list-center a {
  color: #fffa8f;
}
#collegio .p-card-content {
  text-align: left !important;
  padding: 0px !important;
}
#collegio .memberLabels {
  min-width: 140px;
}
</style>