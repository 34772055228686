<template>
  <div id="app-vue" v-if="loginOK">
    <TabMenu :model="items" /> 
    <div class="progress-spinner" v-if="this.$store.state.spinnerON" >
      <ProgressSpinner />
    </div>
    <router-view />
  </div>
  <div id="app-vue" v-else>
    <TabMenu :model="items" /> 
    <div class="progress-spinner" v-if="this.$store.state.spinnerON" >
      <ProgressSpinner />
    </div>
    <router-view />
  </div>

</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: "app",
  components: { ProgressSpinner },
  data() {
    return {
      loginOK: false,
      passValue: null,
      activeIndex: 1,
      items: [
        { label: "Home", icon: "pi pi-home", to: "/" },
        { label: "Annuario", icon: "pi pi-calendar", to: "/annuario", },
        { label: "Altro", icon: "pi pi-star-o", to: "/altro", },
      ],
    };
  },
  created() {
      var localPass = localStorage.getItem('password');
      if ( localPass ) {
        this.$store.commit( 'SET_LOGIN_ACTIF', true )
      }
  },
  mounted() {
//    let webpushr = document.createElement('script')
//    webpushr.setAttribute('src','https://cpv-ofmconv.org/js/webpushr.js')
//      document.body.appendChild(webpushr)
  }
}
</script>

<style>
body,
html {
  margin: 0px;
  background-color: #3278a7;
}
#app-vue {
  background-color: white;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 700px;
  min-height: 100vh;
  margin: 0 auto;
}
.mdc-bottom-navigation {
  width: 700px;
  text-align: center;
  margin: 0 auto;
}
.p-tabmenu {
  position: fixed;
  width: 100%;
  bottom: 0;
  max-width: 700px;
}
.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 6em;
    width: 6em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
</style>