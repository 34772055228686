<template>
  <div id="altro" v-if="this.annuarioDBOpen">
    <div class="titre-principal centre" style="padding: 20px 0">
      Altre Attività
    </div>
    <div
      class="list-center"
      v-for="altro in this.annuarioDB[5]"
      :key="altro.id"
    >
      <router-link 
        :to="{ 
          name: 'altroCategoria', 
          params:  { id: altro.categoria, label: altro.label } 
        }"
      >
        <Card style="margin: 10px">
          <template #content>
            <strong>{{ altro.label.toUpperCase() }}</strong>
          </template>
        </Card>
      </router-link>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: "Altro",
  components: { Card },
  data() {
    return {
      showOption: [],
    };
  },
  computed: {
    ...mapState(['annuarioDB', 'annuarioDBOpen'])
  },
  created() {
    if ( !this.annuarioDBOpen ) {
      console.log('Altro.vue created');
      this.$store.dispatch("getAnnuario"); 
    }
//    console.log( 'altro route', this.$route.path );
    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  methods: {
    showText(index) {
      this.showOption[index] = !this.showOption[index];
    },
  },
  mounted() {
    this.$store.dispatch( "setHighlight", { on: true } ); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch( "setHighlight", { on: false } ); // highlight the tab of tabMenu
  },
};
</script>

<style>
#altro .p-card,
#altro .p-card-body {
  border-radius: 10px !important;
}
#altro .p-card-body {
  background-color: #6c8cbf; /* #c9c9ff; */
}
#altro .p-card-content {
  color: #fffa8f;
  font-size: 22px;
}
#altro .list-center a {
  color: #fffa8f;
}
#altro .p-card-content {
  text-align: left !important;
  padding: 0px !important;
}
#altro a {
  cursor: pointer;
}
</style>