import { createStore } from 'vuex'
import axios from 'axios'
import { useRoute } from 'vue-router';
import router from '../router'
import $ from 'jquery';

export default createStore({
  state: {
    annuarioDB: [],
    annuarioDBOpen: false,
    altroDB: [],
    altroDBOpen: false,
    DB: {},
    DBOpen: false,
    loginSession: false,
    loginPass: "",
    loginActif: false,
    spinnerON: false,
    errorMessage: "",
    viewPDFUrl: "",
    host: "https://test.cpv-ofmconv.org/"
  },
  mutations: {
    SET_ACTIVE_TAB( state, val ) { state.activeTab = val; },
    SET_ANNUARIO_DB( state, val ) { state.annuarioDB = val; },
    SET_ANNUARIO_DB_OPEN( state, val ) { state.annuarioDBOpen = val; },
    SET_ALTRO_DB( state, val ) { state.altroDB = val; },
    SET_ALTRO_DB_OPEN( state, val ) { state.altroDBOpen = val; },
    SET_DB( state, val ) { state.DB = val; },
    SET_DB_OPEN( state, val ) { state.DBOpen = val; },
    SET_LOGIN_SESSION( state, val ) { state.loginSession = val; },
    SET_LOGIN_PASS( state, val ) { state.loginPass = val; },
    SET_LOGIN_LEVEL( state, val ) { state.loginLevel = val; },
    SET_LOGIN_ACTIF( state, val ) { state.loginActif = val; },
    SET_SPINNER_ON( state, val ) { state.spinnerON = val; },
    SET_ERROR_MESSAGE( state, val ) { state.errorMessage = val; },
    SET_VIEWPDF_URL( state, val ) { state.viewPDFUrl = val; },
  },
  actions: {
    getAnnuario({ commit }) {
  //    dispatch("getLoginPass");
      commit( 'SET_SPINNER_ON', true )
      commit( 'SET_ANNUARIO_DB_OPEN', false )
      console.log('getAnnuario action');
      axios.get( this.state.host + 'php/readDBAnnuario.php')
          .then(response => {
              commit( 'SET_ANNUARIO_DB', response.data )
              commit( 'SET_ANNUARIO_DB_OPEN', true )
              console.log( ' getAnnuario = ', response.data )
              commit( 'SET_SPINNER_ON', false )
            })
    },
    getAltroCategoria({ commit }) {
      commit( 'SET_SPINNER_ON', true )
      commit( 'SET_ALTRO_DB_OPEN', false )
      commit( 'SET_ALTRO_DB', {} )
      console.log('getAltroCategoria action');
      console.log('id = ', useRoute().params.id );
      console.log('host = ', this.state.host );
      axios.get( this.state.host + 'php/readDBAltroCategoria.php', { params: { categoria: useRoute().params.id, host: this.state.host } })
          .then(response => {
              commit( 'SET_ALTRO_DB', response.data )
              commit( 'SET_ALTRO_DB_OPEN', true )
              commit( 'SET_SPINNER_ON', false )
              console.log( ' getAltroCategoria = ', response.data )
         })
    },
    loginVerify({ commit, state }) {
      commit( 'SET_SPINNER_ON', true )
      console.log('login action');
      commit( 'SET_LOGIN_LEVEL', 0 );
      axios.get( this.state.host + 'php/login.php', { params: { pass: this.state.loginPass } })
          .then(response => {
              var passLogin = response.data;
              console.log( ' response data = ', response.data );
              commit( 'SET_LOGIN_LEVEL', passLogin.level );
              if ( passLogin.level > 0 ) {
                localStorage.setItem( 'user-level', passLogin.level );
                localStorage.setItem( 'user-password', this.state.loginPass );
                commit( 'SET_ERROR_MESSAGE', "" ); 
                router.push("/"); }
              else {
                commit( 'SET_ERROR_MESSAGE', passLogin.label );
              }
              commit( 'SET_SPINNER_ON', false );
            })
    },
    // Highlight the right tab
    setHighlight( { state }, val ) {
      console.log(state);
      const highlightedItems = document.querySelectorAll(".p-tabmenuitem");
      highlightedItems.forEach(function(userItem) {
        var routeActuelle = useRoute().path;
        var routeduLien = $( userItem  ).find('a').attr('href');
        var trouve = routeActuelle.search(routeduLien);
        var homeclick = (routeduLien == '/' && routeActuelle == '/') ? true : false; 
        if ( val.on && trouve > -1 && routeduLien !== '/' || homeclick ) {
          $( userItem ).addClass( "p-highlight" ); }
        else {
          $( userItem ).removeClass( "p-highlight" );
        }
      });
    },
  }
})
