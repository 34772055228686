<template>
  <div id="scheda" class="centre" v-if="this.annuarioDBOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/collegio/">
        Collegio dei Penitenzieri Vaticani
      </router-link>
    </div>
    <Card style="margin: 10px">
      <template #title>
        <div class="p-card-name">
          {{ this.frate.cognome.toUpperCase() }}
          {{ this.frate.nome }}
        </div>
      </template>
      <template #content style="text-align: left">
        <div class="memberCardImg">
          <!--      <img :src="srcImg(membro.immagine)" /> -->
        </div>

        <div class="giurisdizione">
          <!-- Giurisdizione -->
          <div class="memberGiurisdizione">
            <strong>{{ this.frate.giurisdizione }}</strong>
          </div>
          <!-- Sito web -->
          <div class="memberSito" style="margin-bottom: 15px">
            <a
              :href="this.frate.sitoweb"
              @click="window.open(this.frate.sitoweb, '_blank')"
              target="new"
              >{{ this.frate.sitoweb }}</a
            >
          </div>
        </div>
        <div class="background-1">
          <!-- Telefono interno -->
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. interno:</strong>
            </div>
            <div class="memberData">{{ this.frate.tel }}</div>
          </div>
          <!-- Stanza + piano -->
          <div class="flex">
            <div class="memberLabels">
              <strong>Stanza:</strong>
            </div>
            <div class="memberData">{{ this.frate.stanza }}</div>
            <div class="memberLabels">
              &nbsp;({{ this.frate.piano }}° Piano)
            </div>
          </div>
        </div>
        <div class="background-2">
          <!-- Data nascita -->
          <div class="flex">
            <div class="memberLabels">
              <strong>Compleanno:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.nascita) }} ({{
                calcoloAnni(this.frate.nascita)
              }}
              anni)
            </div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Onomastico:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.onomastico) }}
            </div>
          </div>
        </div>
        <div class="background-1">
          <div class="flex">
            <div class="memberLabels">
              <strong>Professione:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.professione) }}
            </div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Ordinazione:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.ordinazione) }}
            </div>
          </div>
        </div>
        <div class="background-2">
          <div class="flex">
            <div class="memberLabels">
              <strong>E-mail:</strong>
            </div>
            <div class="memberMail">
              <a :href="mailaddr(this.frate.email)">{{ this.frate.email }}</a>
            </div>
          </div>
        </div>
        <div class="background-1">
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. Cellulare:</strong>
            </div>
            <div class="memberData">{{ this.frate.cellulare }}</div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. Famiglia:</strong>
            </div>
            <div class="memberData">{{ this.frate.telfamiglia }}</div>
          </div>
        </div>
        <div class="background-2 border-bottom">
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. all'Estero:</strong>
            </div>
            <div class="memberData">{{ this.frate.telestero }}</div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. Provincia:</strong>
            </div>
            <div class="memberData">{{ this.frate.telprovincia }}</div>
          </div>
        </div>
        <div class="centre orario">Orario Confessioni</div>
        <div class="background-1">
          <div class="flex">
            <div class="mattino-titolo"><strong>Mattino</strong></div>
            <div><strong>Pomeriggio</strong></div>
          </div>
          <div
            class="list-left"
            v-for="orario in this.frate.orari"
            :key="orario.giorno"
          >
            <div class="flex">
              <div class="giorno">
                <strong>{{ this.giorni[orario.giorno] }}:</strong>
              </div>
              <div
                class="flex"
                v-html="orarioMattinoPomeriggio(orario.orario)"
              ></div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: "SchedaFrate",
  components: { Card },
  data() {
    return {
      afficheScheda: false,
      frate: {},
      mesi: [
        "gennaio",
        "febbraio",
        "marzo",
        "aprile",
        "maggio",
        "giugno",
        "luglio",
        "agosto",
        "settembre",
        "ottobre",
        "novembre",
        "dicembre",
      ],
      giorni: {
        lun: "Lunedì",
        mar: "Martedì",
        mer: "Mercoledì",
        gio: "Giovedì",
        ven: "Venerdì",
        sab: "Sabato",
        dom: "Domenica",
      },
    };
  },
  computed: {
    ...mapState(['annuarioDB', 'annuarioDBOpen'])
  },
  created() {
    console.log('enter created')
    if ( !this.annuarioDBOpen ) {
      console.log('Annuario.vue mounted');
      this.$store.dispatch("getAnnuario"); 
    }
    else {
      var cpt = 0;
      console.log('Frati avant boucle', this.annuarioDB);
      for (let membro of this.annuarioDB[1]) {
        if (membro.immagine == this.$route.params.id) {
          break;
        }
        cpt++;
      }
      this.frate = this.annuarioDB[1][parseInt(cpt)];
      console.log('this.frate = ', this.frate )
    }
    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  watch: {
    annuarioDB: function() {
      var cpt = 0;
      console.log('Frati avant boucle', this.annuarioDB);
      for (let membro of this.annuarioDB[1]) {
        if (membro.immagine == this.$route.params.id) {
          break;
        }
        cpt++;
      }
      this.frate = this.annuarioDB[1][parseInt(cpt)];
      console.log('this.frate = ', this.frate )
    }
  },
  methods: {
    mailaddr(mail) {
      return "mailto:" + mail;
    },
    dataFormat(laData) {
      var dN = laData.split(".");
      if (laData.length > 5) var annoDisp = dN[2];
      else annoDisp = "";
      console.log(dN);
      return (
        parseInt(dN[0]) + " " + this.mesi[parseInt(dN[1]) - 1] + " " + annoDisp
      );
    },
    calcoloAnni(birthday) {
      var bd = birthday.split(".");
      console.log("birthday = ", birthday);
      var newBirthday = new Date(
        parseInt(bd[2]),
        parseInt(bd[1]) - 1,
        parseInt(bd[0])
      );
      var ageDifMs = Date.now() - newBirthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    orarioMattinoPomeriggio(orario) {
      var matPom = orario.split("/");
      var mat = matPom[0] ? matPom[0] : "-------------";
      var pom = matPom[1] ? matPom[1] : "-------------";
      return (
        "<span class='mattino'>" +
        mat +
        "</span><span class='pomeriggio'>" +
        pom +
        "</span>"
      );
    },
  },
  mounted() {
    this.$store.dispatch( "setHighlight", { on: true } ); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch( "setHighlight", { on: false } ); // highlight the tab of tabMenu
  },
};
</script>

<style>
#scheda .p-card-body {
  padding-top: 0px !important;
}
#scheda .p-card-content {
  text-align: left !important;
}
#scheda .memberLabels {
  min-width: 140px;
}
#scheda .p-card-name {
  background-color: #6c8cbf;
  color: #fffa8f;
  padding: 10px;
  border-radius: 10px;
}
#scheda .background-1,
#scheda .background-2 {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 0px;
}
#scheda .background-1 {
  background-color: #fffdd3;
  border: 1px solid rgb(255, 230, 0);
}
#scheda .background-2 {
  background-color: white;
  border-left: 1px solid rgb(255, 230, 0);
  border-right: 1px solid rgb(255, 230, 0);
}
#scheda .border-bottom {
  border-bottom: 1px solid rgb(255, 230, 0);
}
#scheda .memberMail a {
  text-decoration: underline;
}
.orario {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}
.giorno {
  min-width: 100px;
}
.mattino,
.pomeriggio {
  min-width: 100px;
}
.mattino-titolo {
  margin: 0 0 5px 100px;
  min-width: 100px;
}
</style>