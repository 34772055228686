<template>
  <div id="penitenzieria" v-if="this.annuarioDBOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/">Annuario</router-link>
    </div>
    <div class="titre-principal centre" style="padding-bottom: 20px">
      Penitenzieria Apostolica
    </div>
    <div class="flex-left-right">
      <div
        class="indirizzo"
        v-html="this.annuarioDB[0][2].indirizzo"
      ></div>
      <div class="portineria-fax">
        <div>
          <strong>Portineria: </strong
          >{{ this.annuarioDB[0][2].tel1 }}
        </div>
        <div>
          <strong>Fax: </strong>{{ this.annuarioDB[0][2].tel2 }}
        </div>
      </div>
    </div>
    <div
      class="list-left"
      v-for="membro in this.annuarioDB[3]"
      :key="membro.id"
    >
      <Card style="margin: 10px">
        <template #content style="text-align: left">
          <div>
            <span v-html="membro.titolo"></span>
            <span v-html="membro.nome"></span>
          </div>
          <div class="funzione-tel">
            <div class="black">
              <em>{{ membro.funzione }}</em>
            </div>
            <div>
              <strong>Tel. fisso: </strong
              ><span class="black">{{ membro.tel }}</span>
            </div>
            <div v-if="membro.cell">
              <strong>Tel. Cell.: </strong
              ><span class="black">{{ membro.cell }}</span>
            </div>
            <div v-if="membro.email">
              <strong>Email: </strong
              ><a :href="mailaddr(membro.email)" target="new">{{
                membro.email
              }}</a>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: "Penitenzieria",
  components: { Card },
  computed: {
    ...mapState(['annuarioDB', 'annuarioDBOpen'])
  },
  created() {
    if ( !this.annuarioDBOpen ) {
      console.log('Penitenzieria.vue created');
      this.$store.dispatch("getAnnuario"); 
    }
    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  methods: {
    mailaddr(mail) {
      return "mailto:" + mail;
    },
  },
  mounted() {
    this.$store.dispatch( "setHighlight", { on: true } ); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch( "setHighlight", { on: false } ); // highlight the tab of tabMenu
  },
};
</script>

<style>
#penitenzieria .p-card,
#penitenzieria .p-card-body {
  border-radius: 10px !important;
}
#penitenzieria .p-card-body {
  background-color: #6c8cbf; /* #c9c9ff; */
}
#penitenzieria .p-card-content {
  color: #fffa8f;
  font-size: 22px;
  padding: 0px !important;
  line-height: 1.1em;
}
#penitenzieria .funzione-tel {
  font-size: 18px;
}
#penitenzieria .funzione-tel a {
  color: black;
}
#penitenzieria .list-left {
  text-align: left;
  line-height: 1.4em;
}
#penitenzieria .flex-left-right {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  font-size: 14px;
}
.portineria-fax {
  text-align: right;
}
.indirizzo {
  text-align: left;
  margin-bottom: 0px;
}
</style>



